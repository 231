/**
 * external libs
 */
import React, { PropsWithChildren, useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

export const Down: React.FC<PropsWithChildren & {subsyles?: {[key: string]: string | number}}> = ({ children, subsyles }) => {
    const controls = useAnimation()
    const [ref, inView] = useInView({ threshold: 0.1 })

    useEffect(() => {
        if (inView) {
            controls.start({
                opacity: 1,
                y: 0,
                transition: { duration: 0.8 },
            })
        } else {
            controls.start({
                opacity: 0,
                y: -100,
                transition: { duration: 0.3 },
            })
        }
    }, [controls, inView])

    return (
        <motion.div ref={ref} initial={{ opacity: 0, y: -100 }} animate={controls} style={{ display: 'flex', ...(subsyles || {}) }}>
            {children}
        </motion.div>
    )
}

export const Up: React.FC<PropsWithChildren & {subsyles?: {[key: string]: string | number}}> = ({ children, subsyles }) => {
    const controls = useAnimation()
    const [ref, inView] = useInView({ threshold: 0.1 })

    useEffect(() => {
        if (inView) {
            controls.start({
                opacity: 1,
                y: 0,
                transition: { duration: 0.8 },
            })
        } else {
            controls.start({
                opacity: 0,
                y: 100,
                transition: { duration: 0.3 },
            })
        }
    }, [controls, inView])

    return (
        <motion.div ref={ref} initial={{ opacity: 0, y: 100 }} animate={controls} style={{ display: 'flex', ...(subsyles || {}) }}>
            {children}
        </motion.div>
    )
}

export const Left: React.FC<PropsWithChildren & {subsyles?: {[key: string]: string | number};delay?: number}> = ({ children, subsyles, delay = 0 }) => {
    const controls = useAnimation()
    const [ref, inView] = useInView({ threshold: 0.1 })

    useEffect(() => {
        if (inView) {
            controls.start({
                opacity: 1,
                x: 0,
                transition: { duration: 0.8, delay: delay },
            })
        } else {
            controls.start({
                opacity: 0,
                x: -100,
                transition: { duration: 0.3 },
            })
        }
    }, [controls, inView])

    return (
        <motion.div ref={ref} initial={{ opacity: 0, x: -100 }} animate={controls} style={{ display: 'flex', ...(subsyles || {}) }}>
            {children}
        </motion.div>
    )
}

export const Right: React.FC<PropsWithChildren & {subsyles?: {[key: string]: string | number}}> = ({ children, subsyles }) => {
    const controls = useAnimation()
    const [ref, inView] = useInView({ threshold: 0.1 })

    useEffect(() => {
        if (inView) {
            controls.start({
                opacity: 1,
                x: 0,
                transition: { duration: 0.8 },
            })
        } else {
            controls.start({
                opacity: 0,
                x: 100,
                transition: { duration: 0.3 },
            })
        }
    }, [controls, inView])

    return (
        <motion.div ref={ref} initial={{ opacity: 0, x: 100 }} animate={controls} style={{ display: 'flex', ...(subsyles || {}) }}>
            {children}
        </motion.div>
    )
}