/**
 * external libs
 */
import React, { useContext, PropsWithChildren, useMemo, useCallback } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { GlobalContentContext, GlobalContext } from '../App'
/**
 * components
 */
import Logo from '../assets/svg/slide_logo.svg'
/**
 * utils
 */
import { policyRoutes } from '../routers/paths'
import { langs } from '../utils/i18n/i18n.constants'
/**
 * types
 */
import { RouteType } from '../types'
/**
 * styles
 */
import styles from './layouts.module.scss'
import headerStyles from '../common-components/header/header.module.scss'
import footerStyles from '../common-components/footer/footer.module.scss'

const PolicyLayout: React.FC<PropsWithChildren> = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()

    const routeData = useMemo<RouteType | undefined>(
        () => policyRoutes.find((r: RouteType) => r.path === location.pathname),
        [location]
    )

    const menuCloseHandler = useCallback((idx: number) => {
        navigate(`/#${idx || ''}`)
    }, [])

    const { handleSetLanguage } = useContext(GlobalContentContext)

    return (
        <div className={styles.policyLayout}>
            <Helmet>
                <title>{routeData?.title ?? '404'}</title>
                <meta name="description" content={routeData?.description ?? ''} />
            </Helmet>

            <header className={`${headerStyles.vheader} ${styles.outternalLayout__vheader}`}>
                <div className="container container_relative">
                    <div className={headerStyles.vheader__container}>
                        <div className={headerStyles.vheader__left}>
                            <img
                                src={Logo}
                                className={styles.outternalLayout__logo}
                                onClick={() => menuCloseHandler(0)}
                            />
                        </div>

                        <div className={headerStyles.vheader__right}>
                            <div className={footerStyles.footer__langs}>
                                {Object.keys(langs).map((lang) => (
                                    <button
                                        key={lang}
                                        type="button"
                                        className={`${footerStyles.footer__lang} ${lang === i18n.language ? footerStyles.active : ''}`}
                                        onClick={() => handleSetLanguage(lang)}>
                                        {lang}
                                        <span className={footerStyles.footer__lang__hover}>{lang}</span>
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <Outlet />
        </div>
    )
}

export default PolicyLayout
