/**
 * external libs
 */
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
/**
 * components
 */
import { ScrollParallax, MoveParallax, Left, Up, Down } from '../../animate'
import { GoldText, H2, H4 } from '../../ui'
/**
 * styles
 */
import styles from './history.module.scss'
import { GlobalContentContext } from '../../../App'
import { determineMediaType } from '../../../utils/utils'

type Props = {
    containerRef: React.RefObject<HTMLDivElement>
}

const History: React.FC<Props> = ({ containerRef }) => {
    const { t } = useTranslation()
    const { homeContent } = useContext(GlobalContentContext)

    const properties = homeContent?.data?.blocks?.brand_coverhistory?.properties

    return (
        <div className={styles.history}>
            <div className={`container container_long ${styles.history__content}`}>
                <div className={styles.history__firstPictureWrapper}>
                    {determineMediaType(properties?.cover?.value || '/videos/history-wheat.mp4') === 'image' ? (
                        <img
                            src={properties?.cover?.value || '/history_1.webp'}
                            alt={properties?.cover?.title || 'history'}
                            className={styles.history__firstPic}
                        />
                    ) : (
                        <video
                            src={properties?.cover?.value || '/videos/history-wheat.mp4'}
                            autoPlay
                            loop
                            controls={false}
                            muted
                            playsInline
                            className={styles.history__firstPic}></video>
                    )}
                    <img
                        src={properties?.cover_logo?.value || '/logo_white.svg'}
                        alt={properties?.cover_logo?.title || 'logo'}
                        className={styles.history__logo}
                    />
                </div>

                <Left subsyles={{ flexShrink: 0 }}>
                    <div className={styles.history__texts}>
                        <GoldText>{properties?.subtitle?.value || t('introduction to the brand history')}</GoldText>
                        <H2>{properties?.title?.value || t('SCHMIDT is an International brand of premium vodka')}</H2>
                    </div>
                </Left>

                {properties?.text_1?.value !== '' && properties?.img_1?.value !== '' ? (
                    <Up subsyles={{ flexShrink: 0 }}>
                        <div className={styles.history__intro}>
                            <H4>{properties?.text_1?.value || ''}</H4>
                            {properties?.img_1?.value !== '' ? (
                                determineMediaType(properties?.img_1?.value || '/history_story_bg.webp') === 'image' ? (
                                    <img
                                        src={properties?.img_1?.value || '/history_story_bg.webp'}
                                        alt={properties?.img_1?.title || 'history'}
                                        className={styles.history__introPic}
                                    />
                                ) : (
                                    <video
                                        src={properties?.img_1?.value || '/videos/history-smith.mp4'}
                                        autoPlay
                                        loop
                                        controls={false}
                                        muted
                                        playsInline
                                        className={styles.history__introPic}></video>
                                )
                            ) : null}
                        </div>
                    </Up>
                ) : null}

                {properties?.text_2?.value !== '' && properties?.img_2?.value !== '' ? (
                    <Down subsyles={{ flexShrink: 0 }}>
                        <div className={`${styles.history__intro} ${styles.history__intro_top}`}>
                            {properties?.img_2?.value ? (
                                determineMediaType(properties?.img_2?.value || '/videos/history-smith.mp4') ===
                                'image' ? (
                                    <img
                                        src={properties?.img_2?.value || '/200_bg.webp'}
                                        alt={properties?.img_2?.title || 'history'}
                                        className={styles.history__introPic}
                                    />
                                ) : (
                                    <video
                                        src={properties?.img_2?.value || '/videos/history-smith.mp4'}
                                        autoPlay
                                        loop
                                        controls={false}
                                        muted
                                        playsInline
                                        className={styles.history__introPic}></video>
                                )
                            ) : null}

                            <H4>{properties?.text_2?.value || ''}</H4>
                        </div>
                    </Down>
                ) : null}

                <div className={styles.history__master}>
                    <div className={styles.history__masterGold}></div>

                    <div className={styles.history__masterParallax} style={{ pointerEvents: 'none' }}>
                        <MoveParallax
                            xStyles={[
                                {
                                    position: 'absolute',
                                    bottom: '8%',
                                    top: '8%',
                                    left: '0',
                                    height: '84%',
                                },
                                {
                                    position: 'absolute',
                                    bottom: '0',
                                    top: '0',
                                    right: '0',
                                },
                            ]}
                            isHorizontal={true}>
                            <img
                                src={properties?.img_3?.value || '/history_master_1.png'}
                                alt={properties?.img_3?.title || 'master'}
                                className={styles.history__masterPic}
                            />
                            <img
                                src={properties?.img_4?.value || '/history_master_2.png'}
                                alt={properties?.img_4?.title || 'master'}
                                className={`${styles.history__masterPic} ${styles.history__masterPic_right}`}
                            />
                        </MoveParallax>
                    </div>

                    <div className={styles.history__masterTextWrapper}>
                        <Down>
                            <p className={styles.history__masterText}>
                                {properties?.text_3?.value ||
                                    t(
                                        'Schmidt is a real story, a real person whose name stands behind the quality of his product'
                                    )}
                            </p>
                        </Down>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default History
