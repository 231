import axios from 'axios'

const url = 'https://adm-dev.schmidt.pairmax.digital/api/'

const getPrivacyContent = async (language: string) => {
    try {
        const response = await axios.get(`${url}pages/privacy`, {
            headers: {
                'Accept-Language': language,
            },
        })
        return response.data
    } catch (err) {
        if (err instanceof Error) {
            throw new Error(err.message)
        } else {
            throw new Error('An unknown error occurred')
        }
    }
}

const getTermOfUseContent = async (language: string) => {
    try {
        const response = await axios.get(`${url}pages/term_of_use`, {
            headers: {
                'Accept-Language': language,
            },
        })
        return response.data
    } catch (err) {
        if (err instanceof Error) {
            throw new Error(err.message)
        } else {
            throw new Error('An unknown error occurred')
        }
    }
}

const getHomeContent = async (language: string) => {
    try {
        const response = await axios.get(`${url}pages/home`, {
            headers: {
                'Accept-Language': language,
            },
        })
        return response.data
    } catch (err) {
        if (err instanceof Error) {
            throw new Error(err.message)
        } else {
            throw new Error('An unknown error occurred')
        }
    }
}

export { getPrivacyContent, getTermOfUseContent, getHomeContent }
