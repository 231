/**
 * external libs
 */
import React, { lazy, Suspense, useState, useEffect } from 'react'
/**
 * components
 */
const Home = lazy(() => import('./Home'))

const HomeComponent = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode }) => {
    const [horizontal, setHorizontal] = useState<boolean | null>(null)

    useEffect(() => {
        const resizeHandler = () => {
            const isHorizontal = window.innerWidth >= window.innerHeight

            if (isHorizontal) {
                document.querySelector('html')?.classList.add('horizontal')
            } else {
                document.querySelector('html')?.classList.remove('horizontal')
            }

            setHorizontal(isHorizontal)
        }

        window.addEventListener('resize', resizeHandler)
        resizeHandler()

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [setHorizontal])

    if (horizontal === null) {
        return null
    }

    return (
        <Suspense fallback={null}>
            <Home {...props} horizontal={horizontal} />
        </Suspense>
    )
}

export default HomeComponent
