/**
 * external libs
 */
import React from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
/**
 * styles
 */
import styles from './cookie.module.scss'

const Cookie: React.FC<{ setIsAcceptedCookie: React.Dispatch<React.SetStateAction<boolean>> }> = ({
    setIsAcceptedCookie,
}) => {
    const { t, i18n } = useTranslation()

    const confirmCookie = () => {
        localStorage.setItem('acceptedCookie', '1')
        setIsAcceptedCookie(true)
    }

    return ReactDOM.createPortal(
        <div className={styles.cookie}>
            <div className={styles.cookie__textBlock}>
                <p className={styles.cookie__title}>{t('Cookies Notice')}</p>

                <p className={styles.cookie__text}>
                    {t('Our site uses cookies. By continuing to use the site, you agree to our use of your cookies')}{' '}
                    <a href="/term_of_use" target="_blank">
                        {t('Read more')}
                    </a>
                </p>
            </div>

            <button className={styles.cookie__button} onClick={confirmCookie}>
                {t('OK, thank you')}
            </button>
        </div>,
        document.body as HTMLElement
    )
}

export default Cookie
