/**
 * external libs
 */
import React, { PropsWithChildren,  useMemo } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
/**
 * types
 */
import { RouteType } from '../types'
/**
 * utils
 */
import { publicRoutes } from '../routers/paths'

const PublicLayout: React.FC<PropsWithChildren> = () => {
    const location = useLocation()
    const routeData = useMemo<RouteType | undefined>(
        () => publicRoutes.find((r: RouteType) => r.path === location.pathname),
        [location]
    )

    return (
        <>
            <Helmet>
                <title>{routeData?.title ?? '404'}</title>
                <meta name="description" content={routeData?.description ?? ''} />
            </Helmet>

            <Outlet />
        </>
    )
}

export default PublicLayout
