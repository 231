function determineMediaType(url: string | undefined): 'image' | 'video' | 'unknown' {
    if (!url) return 'unknown'

    const extension = url.toLowerCase().split('.').pop()?.split('?')[0]
    if (!extension) return 'unknown'

    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg']
    const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'webm', 'mkv']

    if (imageExtensions.includes(extension)) return 'image'
    if (videoExtensions.includes(extension)) return 'video'
    return 'unknown'
}

export { determineMediaType }
