import React, { memo, useContext, useEffect, useRef, useState, useCallback } from 'react'
import './../../utils/react-scroller/styles.scss'
import Scroller, { IScrollerRef } from './../../utils/react-scroller'

import Header from '../header/Header'
import Footer from '../footer/Footer'
import Home from '../slides/home/Home'
import History from '../slides/history/History'
import Products from '../slides/products/Products'
import Production from '../slides/production/Production'
import Cocktails from '../slides/cocktails/Cocktails'
import Contacts from '../slides/contacts/Contacts'

import styles from './horizontal.module.scss'
import { GlobalContentContext } from '../../App'

export const SLIDES = ['home', 'brand history', 'products', 'production', 'cocktails', 'contacts']

const Horizontal: React.FC<{ isStart: boolean }> = memo(({ isStart }) => {
    const [slide, setSlide] = useState(0)
    const containerRef = useRef<IScrollerRef>(null)
    const slideRefs = useRef<HTMLDivElement[]>([])
    const scrollLock = useRef(false)
    const scrollPosition = useRef<number>(0)
    const [selectedSlide, setSelectedSlide] = useState('home')

    const { homeContent } = useContext(GlobalContentContext)
    const cocktails = homeContent?.data?.cocktail_groups || []

    function scrollToElement(element: any) {
        return new Promise<void>((resolve) => {
            // Включаем блокировку скролла и добавляем класс для отключения событий
            scrollLock.current = true
            document.documentElement.classList.add('noEvent')
            // Определяем начальную позицию и целевую позицию
            const targetPosition = element.offsetLeft
            const container = containerRef.current

            if (!container) {
                resolve()
                return
            }

            const tolerance = 30

            // Прокручиваем к целевой позиции
            container.scrollTo(targetPosition)

            // Функция для проверки, достиг ли скролл нужной позиции
            const onScroll = () => {
                const currentPosition = containerRef.current?.scrollRef.current?.scrollLeft || 0
                // Если текущая позиция почти равна целевой, считаем скролл завершенным
                if (Math.abs(currentPosition - targetPosition) <= tolerance) {
                    scrollLock.current = false
                    document.documentElement.classList.remove('noEvent')
                    container.scrollRef.current?.removeEventListener('scroll', onScroll)
                    resolve()
                }
            }

            // Запускаем проверку завершения скролла
            if (container.scrollRef.current) container.scrollRef.current.addEventListener('scroll', onScroll)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const observerOptions = {
        root: containerRef.current?.scrollRef?.current || null,
        rootMargin: '0px',
        threshold: 0.5, // Процент видимости
    }

    useEffect(() => {
        const observer = new IntersectionObserver((entries: any) => {
            entries.forEach((entry: any) => {
                if (entry.isIntersecting) {
                    setSelectedSlide(entry.target.dataset.slideName)
                    console.log(entry.target.dataset.slideName)
                    if (entry.target.dataset.closer && !scrollLock.current) {
                        scrollToElement(entry.target)
                    }
                }
            })
        }, observerOptions)

        const elements = document.querySelectorAll('[data-slide-name]')
        elements.forEach((element) => observer.observe(element))

        return () => {
            elements.forEach((element) => observer.unobserve(element))
            observer.disconnect()
        }
    }, [cocktails])

    return (
        <Scroller ref={containerRef} horizontal={true} scrollLock={scrollLock} className={styles.horizontal}>
            {/* @ts-ignore */}
            <Header slide={selectedSlide} slideRefs={slideRefs} scrollLock={scrollLock} />

            <div
                data-slide-name="home"
                className={styles.horizontal__slide}
                ref={(el) => (slideRefs.current[0] = el as HTMLDivElement)}>
                <Home
                    containerRef={containerRef.current?.scrollRef as React.RefObject<HTMLDivElement>}
                    isStart={isStart}
                />
            </div>

            <div
                data-slide-name="brand history"
                className={styles.horizontal__slide}
                ref={(el) => (slideRefs.current[1] = el as HTMLDivElement)}>
                <History containerRef={containerRef.current?.scrollRef as React.RefObject<HTMLDivElement>} />
            </div>

            <div
                data-slide-name="products"
                className={styles.horizontal__slide}
                ref={(el) => (slideRefs.current[2] = el as HTMLDivElement)}
                data-closer>
                <Products
                    isLeave={slide !== 2}
                    containerRef={containerRef.current?.scrollRef as React.RefObject<HTMLDivElement>}
                    scrollLock={scrollLock}
                />
            </div>

            <div
                data-slide-name="production"
                className={styles.horizontal__slide}
                ref={(el) => (slideRefs.current[3] = el as HTMLDivElement)}>
                <Production containerRef={containerRef.current?.scrollRef as React.RefObject<HTMLDivElement>} />
            </div>

            {cocktails?.map((cocktailSlide: any, idx: number) => (
                <div
                    key={cocktailSlide?.id}
                    className={styles.horizontal__slide}
                    ref={(el) => (slideRefs.current[4 + idx] = el as HTMLDivElement)}
                    id={cocktailSlide?.title?.replace(/\s+/g, '-')?.toLowerCase()}
                    data-slide-name="cocktails"
                    data-closer>
                    <Cocktails
                        containerRef={containerRef.current?.scrollRef as React.RefObject<HTMLDivElement>}
                        list={cocktailSlide?.cocktails}
                        principal={cocktailSlide?.title}
                        scrollLock={scrollLock}
                    />
                </div>
            ))}

            <div
                data-slide-name="contacts"
                data-closer
                className={styles.horizontal__slide}
                ref={(el) => (slideRefs.current[4 + cocktails?.length] = el as HTMLDivElement)}>
                <Contacts />
            </div>

            <Footer isSlide={true} slide={selectedSlide} />
        </Scroller>
    )
})

export default Horizontal
