/**
 * external libs
 */
import React, { memo, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
/**
 * components
 */
import { ScrollParallax, Up } from '../../animate'
import { GoldText, Ico, H2 } from '../../ui'
/**
 * styles
 */
import styles from './home.module.scss'
import { GlobalContentContext } from '../../../App'

type Props = {
    containerRef: React.RefObject<HTMLDivElement>
    isStart: boolean
}

const Home: React.FC<Props> = memo(({ containerRef, isStart }) => {
    const { t } = useTranslation()
    const [start, setStart] = useState(false)

    const { homeContent } = useContext(GlobalContentContext)
    const properties = homeContent?.data?.blocks?.home?.properties

    useEffect(() => {
        if (isStart) {
            setTimeout(() => setStart(true), 500)
        }
    }, [setStart, isStart])

    return (
        <div className={styles.home}>
            <div className={styles.home__bg}>
                <div className={`${styles.home__bgHover} ${start ? styles.home__bgHover_hide : ''}`}></div>
            </div>

            <div className={`container ${styles.home__content}`}>
                <div className={styles.home__bottleWrapper}>
                    <ScrollParallax scrollContainerRef={containerRef}>
                        <img
                            src={properties?.home_bottle_img?.value || '/home_bottle.webp'}
                            alt={properties?.home_bottle_img?.title}
                            className={styles.home__bottle}
                        />
                    </ScrollParallax>
                </div>

                <div className={styles.home__data}>
                    <div className={styles.home__scroll}>
                        <GoldText>{t('scroll on this way')}</GoldText>
                        <Ico
                            ico={
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.8311 12L9.7644 8.93334L10.4669 8.23083L14.2361 12L10.4669 15.7692L9.7644 15.0667L12.8311 12Z" />
                                </svg>
                            }
                            filled={true}
                        />
                    </div>

                    <Up subsyles={{ flexGrow: 1, flexDirection: 'column' }}>
                        <div className={styles.home__texts}>
                            <div className={styles.home__textsTop} />

                            <div className={styles.home__title}>
                                <H2>{properties?.title?.value || t('Schmidt — premium vodka')}</H2>
                            </div>

                            <div className={styles.home__textsMiddle} />

                            <div className={styles.home__advantages}>
                                <div className={styles.home__advantage}>
                                    <img
                                        src={properties?.subtitle_img_1?.value || '/home_advantage1.webp'}
                                        alt={properties?.subtitle_img_1?.title || t('Pure composition without sugar')}
                                        className={styles.home__advantageIco}
                                    />
                                    <GoldText>
                                        {properties?.subtitle_1?.value || t('Pure composition without sugar')}
                                    </GoldText>
                                </div>

                                <div className={styles.home__advantage}>
                                    <img
                                        src={properties?.subtitle_img_2?.value || '/home_advantage2.webp'}
                                        alt={
                                            properties?.subtitle_img_2?.title ||
                                            t('Unique smoothness thanks to filtration through pearls')
                                        }
                                        className={styles.home__advantageIco}
                                    />
                                    <GoldText>
                                        {properties?.subtitle_2?.value ||
                                            t('Unique smoothness thanks to filtration through pearls')}
                                    </GoldText>
                                </div>
                            </div>

                            <div className={styles.home__textsBottom} />
                        </div>
                    </Up>
                </div>
            </div>
        </div>
    )
})

export default Home
