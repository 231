/**
 * external libs
 */
import React, { memo, useContext } from 'react'
import { useTranslation } from 'react-i18next'
/**
 * components
 */
import { ScrollParallax, MoveParallax, Left } from '../../animate'
import { GoldText, Ico, H2, H3, Text } from '../../ui'
/**
 * types
 */
import { ProductionType } from '../../../types'
/**
 * styles
 */
import styles from './production.module.scss'
import { GlobalContentContext } from '../../../App'

type Props = {
    containerRef: React.RefObject<HTMLDivElement>
}

const productionItemsPlaceholder: ProductionType[] = [
    {
        id: '1',
        title: 'alcohol',
        img: '/prod1.webp',
        number: '01',
        describe: 'We use premium grain alcohol produced at our own plants from raw materials of highest quality',
    },
    {
        id: '2',
        title: 'water',
        img: '/prod2.webp',
        number: '02',
        describe:
            'Water is extracted from our own well located on the distillery territory at a depth of more than 220 meters. It is gently filtered without thermal treatment and softened through additional ion filtration to give it a special smoothness',
    },
    {
        id: '3',
        title: 'blending',
        img: '/prod3.webp',
        number: '03',
        describe:
            'Alcohol is mixed with water under high pressure and is cooled to minus 15 degrees for additional segmentation of possible impurities',
    },
    {
        id: '4',
        title: 'filtration',
        img: '/prod4.webp',
        number: '04',
        describe:
            'Schmidt vodka is filtered without additional pressure through a charcoal filter made from maple, coconut palm and birch wood. Then vodka undergoes a unique filtration process through natural white pearls giving vodka its unique velvety smoothness',
    },
]

const Production: React.FC<Props> = memo(({ containerRef }) => {
    const { t } = useTranslation()

    const { homeContent } = useContext(GlobalContentContext)
    const properties = homeContent?.data?.blocks?.production?.properties
    let productionItems = []

    for (let i = 1; i <= 4; i++) {
        const newObj = {
            id: i.toString(),
            img: properties?.[`advantage_img_${i}`]?.value || productionItemsPlaceholder[i - 1].img,
            number: properties?.[`advantage_number_${i}`]?.value || productionItemsPlaceholder[i - 1].number,
            text: properties?.[`advantage_text_${i}`]?.value || productionItemsPlaceholder[i - 1].describe,
            title: properties?.[`advantage_title_${i}`]?.value || productionItemsPlaceholder[i - 1].title,
        }
        productionItems.push(newObj)
    }

    return (
        <div className={styles.production}>
            <div className={`container container_long ${styles.production__content}`}>
                <div className={styles.production__titleWrapper}>
                    <div className={styles.production__title}>
                        <div className={styles.production__scroll}>
                            <GoldText>{properties?.subtitle?.value || t("let's explore")}</GoldText>

                            <Ico
                                ico={
                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.8311 12L9.7644 8.93334L10.4669 8.23083L14.2361 12L10.4669 15.7692L9.7644 15.0667L12.8311 12Z" />
                                    </svg>
                                }
                                filled={true}
                            />
                        </div>

                        <H2>{properties?.title?.value || t('production')}</H2>
                    </div>
                </div>

                <Left subsyles={{ flexShrink: 0 }}>
                    <div className={styles.production__firstPictureWrapper}>
                        <img
                            src={properties?.img?.value || '/production.webp'}
                            alt={properties?.img?.title || t('production')}
                            className={styles.production__firstPic}
                        />
                    </div>
                </Left>

                <div className={styles.production__itemsWrapper}>
                    <div className={styles.production__items}>
                        {productionItems.map((item) => (
                            <div key={item.id} className={styles.production__item}>
                                <div className={styles.production__picture}>
                                    <div className={styles.production__bg}>
                                        <MoveParallax
                                            config={[
                                                {
                                                    xFactor: 0.03,
                                                    yFactor: -0.05,
                                                    springSettings: {
                                                        stiffness: 100,
                                                        damping: 30,
                                                    },
                                                },
                                            ]}>
                                            <img
                                                src={item.img}
                                                alt={t(item.title)}
                                                className={styles.production__itemPic}
                                            />
                                        </MoveParallax>
                                    </div>
                                </div>

                                <div className={styles.production__itemName}>
                                    <p className={styles.production__itemNumber}>{item.number}</p>

                                    <div className={styles.production__itemNameWrapper}>
                                        <H3>{t(item.title)}</H3>
                                    </div>
                                </div>

                                <Text>{t(item.text)}</Text>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
})

export default Production
