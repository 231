/**
 * external libs
*/
import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next'
/**
 * components
*/
import { H2, GoldText, Button } from "../../common-components/ui";
/**
 * styles
*/
import styles from './error-pages.module.scss';


const ErrorPage: React.FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation()

    const backHandler = () => {
        navigate(`/`, { replace: true })
    }

    return (
        <div className={`container ${styles.page404}`}>
            <H2>404</H2>
            <GoldText>{t("Schmidt created vodka, but not this page")}</GoldText>
            <Button
                label={t("Back to home")}
                type="button"
                subClasses={styles.page404__button}
                handler={backHandler}
            />
        </div>
    );
};

export default ErrorPage;