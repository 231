/**
 * external libs
 */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
/**
 * components
 */
import { GoldText, Text, CustomLink } from '../../ui'
/**
 * types
 */
import { CocktailType } from '../../../types'
/**
 * styles
 */
import styles from './cocktails.module.scss'

type Props = {
    containerRef: React.RefObject<HTMLDivElement>
    list: CocktailType[]
    principal: string
    scrollLock: React.RefObject<boolean>
}

const Cocktails: React.FC<Props> = ({ containerRef, list, principal, scrollLock }) => {
    const { t } = useTranslation()
    const [activePoint, setActivePoint] = useState<any | null>(list[0])

    useEffect(() => {
        const active = list?.find((item: any) => item?.id === activePoint?.id)
        if (active) {
            setActivePoint(active)
        } else {
            setActivePoint(null)
        }
    }, [list])

    if (!activePoint) {
        return null
    }

    const scrollToProducts = () => {
        //@ts-ignore
        scrollLock.current = true
        document.querySelectorAll('#products')?.[0]?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
        })
        setTimeout(() => {
            //@ts-ignore
            scrollLock.current = false
        }, 500)
    }

    // console.log(list)

    return (
        <div className={styles.cocktails}>
            <div className={`container container_long ${styles.cocktails__content}`}>
                <div className={styles.cocktails__compound}>
                    <div className={styles.cocktails__table}>
                        <div className={styles.cocktails__tableTitle}>
                            <GoldText>{t('ingredients')}</GoldText>
                        </div>

                        <div className={styles.cocktails__tableList}>
                            {(activePoint?.ingredients || [])?.map((ingredient: any, index: number) => (
                                <div key={index} className={styles.cocktails__lineData}>
                                    <p className={`${styles.cocktails__textData} ${styles.cocktails__textData_label}`}>
                                        {t(ingredient.title)}
                                    </p>
                                    <p className={styles.cocktails__textData}>
                                        {
                                            //@ts-ignore
                                            isNaN(ingredient.value)
                                                ? t(ingredient.value)
                                                : `${ingredient.value} ${t('ml')}`
                                        }
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={styles.cocktails__table}>
                        <div className={styles.cocktails__tableTitle}>
                            <GoldText>{t('how to make')}</GoldText>
                        </div>

                        <p className={styles.cocktails__recipe}>{t(activePoint?.how_to_make)}</p>
                    </div>
                </div>

                <img src={activePoint?.img} alt={t(activePoint.title)} className={styles.cocktails__pic} />

                <div className={styles.cocktails__right}>
                    <GoldText>{t(principal)}</GoldText>

                    <div className={styles.cocktails__submenu}>
                        {list?.map((item) => (
                            <CustomLink
                                key={item.id}
                                title={t(item.title)}
                                to="null"
                                onClickHandler={() => setActivePoint(item)}
                                isBlack={item.id === activePoint?.id}
                                routineAnim={true}
                            />
                        ))}
                    </div>

                    <Text className={styles.cocktails__description}>{t(activePoint?.description)}</Text>
                    <CustomLink
                        title={t('backToProduct')}
                        to="null"
                        isProductLink={true}
                        onClickHandler={() => scrollToProducts()}
                        isBlack={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default Cocktails
