/**
 * components
*/
import Home from "../pages/home/HomeLazy";
import Privacy from "../pages/privacy/Privacy";
import TermOfUse from "../pages/term-of-use/TermOfUse";
/**
 * types
*/
import { RouteType } from "../types";

export const publicRoutes: RouteType[] = [
    { path: "/", exact: true, component: Home, title: "Главная", description: "Главная" },
];

export const policyRoutes: RouteType[] = [
    { path: "/privacy", exact: true, component: Privacy, title: "Privacy", description: "Privacy" },
    { path: "/term_of_use", exact: true, component: TermOfUse, title: "Term of use", description: "Term of use" },
];


