/**
 * external libs
 */
import React from 'react'
/**
 * styles
 */
import styles from './input.module.scss'

type Props = {
    type?: 'text' | 'password'
    placeholder?: string
    isError?: boolean
    value: string
    change: (e: React.ChangeEvent<HTMLInputElement> | { target: { value: string } }) => void
    info?: boolean
}

const Input: React.FC<Props> = ({
    change,
    type = 'text',
    placeholder = '',
    isError = false,
    value = '',
    info = false,
}) => {
    return (
        <input
            type={type}
            autoComplete="off"
            className={`${styles.input} ${isError ? styles.input_error : ''} ${info ? styles.disabled : ''}`}
            placeholder={placeholder}
            value={value}
            onChange={change}
            onBlur={() => change({ target: { value: value.trim() } })}
            disabled={info}
        />
    )
}

export default Input
