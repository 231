/**
 * external libs
 */
import React, { createContext, useState, useRef, useCallback, useEffect } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { I18nextProvider } from 'react-i18next'
import { getHomeContent, getPrivacyContent, getTermOfUseContent } from './utils/api'
/**
 * components
 */
import Router from './routers/Router'
import Alerts from './common-components/alerts/Alerts'
import Loader from './common-components/loader/Loader'
import Cookie from './common-components/cookie/Cookie'
/**
 * utils
 */
import i18n from './utils/i18n/i18n'
/**
 * types
 */
import { GlobalContextType, AlertType, AlertContentType, GlobalContentContextType } from './types'
/**
 * styles
 */
import './global.scss'

export const GlobalContext = createContext<GlobalContextType>({
    addAlert: null,
    setLoaderCount: null,
    isBelarus: null,
})

export const GlobalContentContext = createContext<GlobalContentContextType>({
    privacyContent: null,
    termOfUseContent: null,
    homeContent: null,
    handleSetLanguage: () => {},
})

function App() {
    const [alerts, setAlerts] = useState<AlertType[]>([])
    const [loaderCount, setLoaderCount] = useState<number>(0)
    const alertId = useRef<number>(0)
    const [language, setLanguage] = useState<string>(localStorage.getItem('i18nextLng') || 'ru')
    const [isAcceptedCookie, setIsAcceptedCookie] = useState(!!localStorage.getItem('acceptedCookie'))
    const [isBelarus, setIsBelarus] = useState<boolean | null | string>(localStorage.getItem('isBelarus'))
    // Объединенное состояние для всех данных
    const [allContent, setAllContent] = useState({
        privacyContent: null,
        termOfUseContent: null,
        homeContent: null,
    })

    const addAlert = useCallback(
        (alert: AlertContentType) => {
            alertId.current = alertId.current + 1
            setAlerts((pre) => [...pre, { ...alert, id: alertId.current }])
        },
        [alertId]
    )

    const handleSetLanguage = useCallback(
        (lang: string) => {
            console.log('Changing language to:', lang)
            if (lang !== language) {
                i18n?.changeLanguage(lang)
                setLanguage(lang)
            }
        },
        [language]
    )

    function httpGetAsync(url: string, callback: Function) {
        const xmlHttp = new XMLHttpRequest()
        xmlHttp.onreadystatechange = function () {
            if (xmlHttp.readyState === 4 && xmlHttp.status === 200) callback(xmlHttp.responseText)
        }
        xmlHttp.open('GET', url, true)
        xmlHttp.send(null)
    }

    const url = 'https://ipwho.is/'

    useEffect(() => {
        console.log('Fetching Belarus status', isBelarus)
        if (isBelarus !== null) return

        httpGetAsync(url, function (response: any) {
            const data = JSON.parse(response)
            const countryCode = data.country_code
            console.log(countryCode)
            if (countryCode === 'BY') {
                console.log('Belarus detected')
                setIsBelarus(true)
                localStorage.setItem('isBelarus', 'true')
            } else {
                console.log('Belarus not detected')
                setIsBelarus(false)
                localStorage.setItem('isBelarus', 'false')
            }
        })
    }, [])

    useEffect(() => {
        console.log('Fetching content due to language change:', language)

        let isMounted = true // Флаг для проверки, что компонент смонтирован

        const fetchAllContent = async () => {
            try {
                // Запрашиваем все данные одновременно
                const [privacy, terms, home] = await Promise.all([
                    getPrivacyContent(language),
                    getTermOfUseContent(language),
                    getHomeContent(language),
                ])

                if (isMounted) {
                    let homeFiltred = home
                    homeFiltred.data.bottles = home.data.bottles.filter((item: any) => {
                        console.log(item.title)
                        // if (item.title === 'Black') {
                        //     return !!isBelarus
                        // }
                        return true
                    })
                    console.log('Setting content:', privacy, terms, homeFiltred, language, `is belarus: ${isBelarus}`)
                    // Обновляем все состояния вместе
                    setAllContent({
                        privacyContent: privacy,
                        termOfUseContent: terms,
                        homeContent: homeFiltred,
                    })
                }
            } catch (error) {
                console.error('Failed to fetch content:', error)
            }
        }

        fetchAllContent()

        return () => {
            isMounted = false // Отмена выполнения, если компонент будет размонтирован
        }
    }, [language])

    return (
        <I18nextProvider i18n={i18n}>
            <GlobalContext.Provider value={{ addAlert, setLoaderCount, isBelarus }}>
                <GlobalContentContext.Provider value={{ ...allContent, handleSetLanguage }}>
                    <Alerts alerts={alerts} setAlerts={setAlerts} />
                    {loaderCount > 0 && <Loader />}

                    {!isAcceptedCookie && <Cookie setIsAcceptedCookie={setIsAcceptedCookie} />}

                    <HelmetProvider>
                        <Router />
                    </HelmetProvider>
                </GlobalContentContext.Provider>
            </GlobalContext.Provider>
        </I18nextProvider>
    )
}

export default React.memo(App)
