/**
 * external libs
 */
import React, { useContext } from 'react'
import { useTranslation, Trans } from 'react-i18next'
/**
 * components
 */
import { H2, GoldText, Text } from '../../common-components/ui'
/**
 * styles
 */
import styles from './privacy.module.scss'
import { Link } from 'react-router-dom'
import { GlobalContentContext } from '../../App'

const TermOfUse: React.FC = () => {
    const { t } = useTranslation()
    const { termOfUseContent } = useContext(GlobalContentContext)

    return (
        <div className={`container ${styles.privacy__container}`}>
            <div className={styles.privacy}>
                <Link to="/">
                    <GoldText>{t('back')}</GoldText>
                </Link>
                <div dangerouslySetInnerHTML={{ __html: termOfUseContent?.data?.description }}></div>
            </div>
        </div>
    )
}

export default TermOfUse
